import React, { useState, useEffect } from "react";
import cx from "classnames";
import styled from "styled-components";
import PropTypes from "prop-types";
import { IconButton } from "components/components/atoms";
import { scrollToTop } from "utils/scrollTo";
import pageTypes from "utils/pageTypes";
import useBreakpoint from "utils/hooks/useBreakpoint";

const StyledTopButton = styled.button`
  z-index: 50;
`;

const ScrollToTopButton = ({ pageType }) => {
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);
  const breakpoint = useBreakpoint(
    typeof window !== "undefined" && window.innerWidth
  );
  const isMobile = ["sm", "xs", "md"].includes(breakpoint);

  const handleOnScroll = () => {
    const currentScrollY = window.scrollY;
    const offset = 300;
    if (currentScrollY > offset) {
      setScrollButtonVisible(true);
    } else if (scrollButtonVisible) {
      setScrollButtonVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);
    return () => window.removeEventListener("scroll", handleOnScroll);
  }, [handleOnScroll]);

  useEffect(() => {
    let chatBoxDiv = document.getElementById("drift-frame-controller");
    let hasAnchorBar =
      pageType === pageTypes.CommunityPage ||
      pageType === pageTypes.CondoPage ||
      pageType === pageTypes.PlanPage ||
      pageType === pageTypes.CondoPlan ||
      pageType === pageTypes.QmiPage;
    if (chatBoxDiv) {
      chatBoxDiv.style.right = isMobile ? "-4px" : "8px";
      chatBoxDiv.style.bottom = isMobile
        ? hasAnchorBar
          ? "83px"
          : "-5px"
        : "2px";
    }
  });

  return (
    <StyledTopButton
      className={cx("w-full h-auto flex flex-col bottom-0 fixed px-0", {
        "mb-20 md:mb-16 lg:mb-0":
          pageType === pageTypes.CommunityPage ||
          pageType === pageTypes.CondoPage ||
          pageType === pageTypes.PlanPage ||
          pageType === pageTypes.CondoPlan ||
          pageType === pageTypes.QmiPage,
        hidden: !scrollButtonVisible,
      })}
    >
      <IconButton
        className={cx(
          "absolute left-0 bottom-0 mb-2 md:mb-4 mx-2 md:mx-4 h-14-mattamy w-14-mattamy",
          {
            "mb-4":
              pageType === pageTypes.CommunityPage ||
              pageType === pageTypes.CondoPage ||
              pageType === pageTypes.PlanPage ||
              pageType === pageTypes.CondoPlan ||
              pageType === pageTypes.QmiPage,
            "mb-2": !(
              pageType === pageTypes.CommunityPage ||
              pageType === pageTypes.CondoPage ||
              pageType === pageTypes.PlanPage ||
              pageType === pageTypes.CondoPlan ||
              pageType === pageTypes.QmiPage
            ),
          }
        )}
        icon="arrow-up"
        id="ScrollToTopNavigationButton"
        circled
        shadowed
        size
        color="white"
        onClick={() => {
          scrollToTop();
          const navElement = document.querySelector('[role="navigation"]');
        
          if (navElement) {
            navElement.setAttribute("tabindex", "0");
            navElement.focus();
            navElement.setAttribute("tabindex", "-1");
          }
        }}
        role="link"
        buttonLabel={"Navigate to Top."}
        onKeyDown={(e) => {
          const navElement = document.getElementById("countryContainer");
          if (e.shiftKey && e.key === "Tab") {
            if (navElement) {
              scrollToTop();
              setTimeout(() => {
                navElement.firstElementChild.firstChild.focus();
              }, 100);
            }

            const element = document.getElementById("FlyoutButton");
            if (element) {
              const promosPanel = document.getElementById(
                "FlyoutCardContainer"
              );
              if (promosPanel) {
                element.parentElement.lastChild.focus();
              } else {
                element.focus();
              }
            } else {
              const element = document.getElementById("LastFooterLink");
              if (element) {
                element.focus();
              }
            }
          } 
        }}
      />
    </StyledTopButton>
  );
};

ScrollToTopButton.propTypes = {
  scrollToSectionName: PropTypes.string,
};

export default ScrollToTopButton;
